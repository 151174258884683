var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("circular-count-down-timer", {
        attrs: {
          circles: _vm.example.circles,
          "main-circle-id": _vm.example.mainCircleId || "1"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }