<template>
  <div>
    <circular-count-down-timer
        :circles="example.circles"
        :main-circle-id="example.mainCircleId || '1'"
    />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "App",
  props: ['date'],
  data() {
    return {
      example: {
        title: 'Decremental counter',
        circles: [
          {
            id: '4',
            steps: 31,
            value: this.getDateDiff('days'),
            size: 100,
            strokeWidth: 5,
            label: 'Tagen',
            underneathStrokeColor: '#9f9577',
            strokeColor: '#13295b'
          },
          {
            id: '3',
            steps: 24,
            value: this.getDateDiff('hours'),
            size: 100,
            label: 'Stunden',
            stepLength: -1,
            strokeWidth: 5,
            dependentCircles: ['4'],
            underneathStrokeColor: '#9f9577',
            strokeColor: '#13295b'
          },
          {
            id: '2',
            steps: 60,
            size: 100,
            value: this.getDateDiff('minutes'),
            stepLength: -1,
            label: 'Minuten',
            strokeWidth: 5,
            dependentCircles: ['3'],
            underneathStrokeColor: '#9f9577',
            strokeColor: '#13295b'
          },
          {
            id: '1',
            steps: 60,
            size: 100,
            value: this.getDateDiff('seconds'),
            classList: 'hidden',
            stepLength: -1,
            label: 'Sekunden',
            strokeWidth: 5,
            dependentCircles: ['2'],
            underneathStrokeColor: '#13295b',
            strokeColor: '#9f9577'
          }
        ]
      },
    }
  },
  methods: {
    getDateDiff(type) {
      var now = moment(new Date()); //todays date
      var end = moment(this.date); // another date
      if (type === 'days'){
        return Math.abs(now.diff(end, type),10)
      }
      var duration = moment.duration(end.diff(now));
      return duration._data[type];
    }
  },
}
</script>

<style>
.circle__value {
  font-size: 30px;
}
</style>